.progressbar {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-left: 0;
  width: 100%;
  overflow-x: hidden;
}
.progressbar li {
  transition: all 200ms ease-in;
  width: 100%;
}

.progressbar .status {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 200ms ease-in;
}

.progressbar .status p {
  font-weight: 400;
  margin: 10px 0 0 0;
  text-align: center;
}

.progressbar .status:before {
  content: "";

  background: #8a8b8e;
  position: absolute;
  top: 28px;
  left: 0;
  transform: translateX(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progressbar li.active .status::before {
  background: #003153;
}

li.active div.status p {
  color: #003153;
}

div.status.nextStep p {
  font-weight: 600;
  color: #000000;
}

div.status p {
  color: #8a8b8e;
}

div.status div {
  align-content: center;
  width: 60px;
  align-self: center;
  height: 60px;
  background: #8a8b8e;
  border-radius: 41px;
  display: flex;
  justify-content: center;
}

.progressbar_step.active div div {
  background: #003153;
}

div.status div svg {
  align-self: center;
}

div.status > svg {
  position: absolute;
  left: calc(50% + 15px);
  top: 0px;
  z-index: 5;
  background: green;
  color: #000;
  position: absolute;
  left: calc(50% + 14px);
  top: -2px;
  z-index: 5;
  background: green;
  border-radius: 15px;
  padding: 3px;
}
