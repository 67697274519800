.captchaContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 3px;
  background-color: #ffffff;
}

.captchaImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.captchaInputContainer {
  display: flex;
  flex-direction: row;
}

.captchaInputContainer input {
  flex-grow: 1;
}

.captchaContainer button {
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #9d9da9;
  font-size: 20px;
  color: black;
  padding: 0;
}
.captchaContainer button svg {
  vertical-align: middle;
  padding: 2px;
}

.inputField {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.responseMessage {
  display: flex;
  align-items: center;
  background-color: #d4edda;
  color: #155724;
  text-align: center;
  justify-content: center;
  font-size: 0.8em;
  padding: 5px;
}

.responseMessage.errorMessage {
  color: #721c24;
  background-color: #f8d7da;
}

.responseMessage:not(.errorMessage)::before {
  content: "✔️";
  padding-right: 10px;
}

.errorMessage::before {
  content: "❗";
  padding-right: 10px;
}

.audioControl {
  position: absolute;
  overflow: hidden;
  left: 3px;
  top: 3px;
  width: 30px;
  height: 27px;
  border-radius: 3px 1px 16px 1px;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-left: 1px;
  padding-top: 1px;
  background-color: #ffffff;
  border: 1px solid;
}

.audioControlIcon {
  outline: 0;
  border: 0;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-style: solid;
  padding: 0;
  border: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  font-family: mticon2;
  font-size: 14pt;
  color: #333;
  display: none;
}

.audioControl label {
  cursor: pointer;
  padding-right: 2px;
}
