* {
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  background: #e0dfdf;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 20px;
}

#root {
  min-height: 100vh;
  position: relative;
}

.buttonCombo {
  padding-top: 20px;
  border-top: 3px solid #00335b;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.formField.legend {
  margin-bottom: -15px;
}

span.required {
  padding-left: 5px;
}

#privacyPolicy:required {
  display: none;
}
#privacyPolicy:required:invalid + label::before {
  content: "\2610";
  padding-right: 0.2em;
  color: red;
}
#privacyPolicy:required:valid + label::before {
  content: "\2611";
  padding-right: 0.2em;
  color: green;
}

.categories {
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  column-gap: 10px;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 12px 12px 22px 12px;
  border: 1px solid #d3d3d3;
  overflow-wrap: anywhere;
}

.category {
  flex-basis: calc(33% - 2px);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  /* flex-grow: 1; */
  min-width: min(400px, 100%);
}

.category h4 {
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
  columns: #00335b;
}

.topics {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-left: 35px;
}

/* .topics.expanded {
  display: flex;
  height: auto;
} */
.categoryHead {
  display: flex;
  column-gap: 30px;
}
.categoryLabel {
  flex-grow: 1;
}
button.accordion {
  display: none;
  width: 0;
}

#page-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 15rem;
}

#content-wrap {
  padding-bottom: 2rem;
}

.container {
  border-radius: 5px !important;
  display: flex;
  flex-direction: column;
  padding-right: 4vw;
  padding-left: 4vw;
}

#loadedContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

svg.emailIcon {
  vertical-align: middle;
  margin-right: 5px;
  border: 1px solid black;
  border-radius: 25px;
  padding: 2px;
}

button.primary {
  color: #ffffff;
  background-color: #00335b;
}

button {
  padding: 1em 1em;
  border-width: 0px;
  color: #00335b;
  border: 1px solid #00335b;
  background-color: #ffffff;
  border-radius: 0;
  font-size: 1rem;
  cursor: pointer;
  clear: both;
}

.buttonDescription {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.modalButtonContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.footerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  font-size: 13px;
  padding-left: 55px;
  color: #6b6b6b;
}

.notice {
  color: #6b6b6b;
  font-size: 15px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #00335b;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 350px;
  background-color: #00335b;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 11;
  bottom: 125%;
  left: 50%;
  margin-left: -180px;
  border: 1px solid #ffffff;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #00335b transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

[type="checkbox"].switch {
  position: relative;
  left: 30px;
  top: 0px;
  z-index: 0;
  -webkit-appearance: none;
}

[type="checkbox"].switch + label {
  position: relative;
  display: block;
  cursor: pointer;
  line-height: 1.3;
  padding-left: 55px;
  position: relative;
  margin-top: -25px;
}

[type="checkbox"].switch + label:before {
  width: 40px;
  height: 17px;
  border-radius: 25px;
  border: 2px solid #ddd;
  background-color: #eee;
  content: "";
  margin-right: 15px;
  transition: background-color 0.5s linear;
  z-index: 5;
  position: absolute;
  left: 0px;
  top: 4px;
}

[type="checkbox"].switch + label:after {
  width: 16px;
  height: 16px;
  border-radius: 25px;
  background-color: #fff;
  content: "";
  transition: margin 0.1s linear;
  box-shadow: 0px 0px 5px #aaa;
  position: absolute;
  left: 2px;
  top: 6px;
  z-index: 10;
}

[type="checkbox"].switch:checked + label:before {
  background-color: #00335b;
}

[type="checkbox"].switch:checked + label:after {
  margin: 0 0 0 23px;
}

.registrationForm,
.requestForm {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.formFields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;
}

.formField {
  display: flex;
  flex-direction: column;
  min-width: min(600px, 100%);
}

.formField input,
.formField select {
  width: min(800px, 100%);
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
}

.formField.checkbox {
  display: inline;
}

.formField.checkbox input[type="checkbox"] {
  width: 30px;
}

.formField label,
legend {
  line-height: 1.8em;
}

.formField input:focus {
  outline-offset: 4px;
  outline-width: 2px;
  outline-style: solid;
}

form button {
  width: min(800px, 100%);
}

header {
  height: 450px;
}
header div.blur {
  width: calc(100% - 8vw);
  height: 100px;
  position: absolute;
  backdrop-filter: blur(10px);
  padding-top: min(2.2vw, 30px);
  padding-left: 4vw;
  padding-right: 4vw;
  display: flex;
  justify-content: space-between;
}

header a img {
  width: 100%;
}

.logoMobile {
  width: 50px;
  display: none;
}
.logo {
  width: 300px;
}

.headerBackground {
  width: 100%;
  height: 100%;
}

.headerBackground img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: clamp(-150px, calc(100vw - 870px), 0px) calc((max(100vw, 870px) - 870px) * -0.08);
}

div.languageSwitcher {
  font-size: 1.5rem;
  color: #fff;
}

div.languageSwitcher button {
  padding: 1em 1em;
  border-width: 0px;
  color: #ffff;
  background-color: #00345b00;
  border-radius: 0;
  font-size: 1.5rem;
  cursor: pointer;
  clear: both;
}

.userMessageWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.successMessageWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

div.backContainer {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-top: 70px;
}

input.invalid,
div.invalid {
  border: 2px solid red;
}

.validationError {
  color: red;
}

.captchaWrapper {
  max-width: 350px;
}

footer {
  position: absolute;
  bottom: 0;
  height: 8rem;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 10px;
  color: #ffffff;
}

footer .footer-container {
  position: relative;
  height: 100%;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.2em;
  color: #ffffff;
  padding-bottom: 10px;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}

.footer-content a {
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  color: rgb(59, 59, 59);
}

h2 {
  line-height: calc(1.4 * (1 + (1 - 2.6) / 25));
  margin: 2rem 0;
}

h4 {
  line-height: calc(1.4 * (1 + (1 - 1.2) / 25));
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000033;
  z-index: 999;
}

.loadingDiv .lds-ring {
  position: sticky;
}

.lds-ring {
  display: inline-block;
  position: inherit;
  left: 50%;
  top: 50%;
  align-self: center;
  width: 80px;
  height: 80px;
  margin-top: 50px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #00335b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00335b transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1620px) {
  h2 {
    font-size: calc(2.6 * 1rem);
  }

  h4 {
    font-size: calc(1.2 * 1rem);
  }

  label,
  legend {
    font-size: calc(1.1 * 1rem) !important;
  }

  em {
    font-size: calc((1.1 - 1) * 1.2vw + 1rem) !important;
  }

  * {
    font-size: calc(0.9 * 1rem);
  }
}

@media screen and (min-width: 768px), screen and (max-width: calc(1619px)) {
  h2 {
    font-size: calc((1.2 - 1) * 1.2vw + 1.2rem);
  }

  h4 {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  }

  label,
  legend,
  div.languageSwitcher button {
    font-size: calc((1.1 - 1) * 1.2vw + 1rem) !important;
  }

  * {
    font-size: calc((1.1 - 1) * 1.2vw + 0.75rem);
  }
}

@media only screen and (max-width: 600px) {
  .section {
    flex-direction: column;
  }

  button:not(.language) {
    width: 100%;
  }
}

@media only screen and (max-width: 799px) {
  .category {
    min-width: 100%;
  }

  .topics {
    display: none;
  }

  .topics.expanded {
    display: flex;
    height: auto;
  }

  button.accordion {
    display: block;
    height: 25px;
    width: 25px;
    padding: 0;
    align-self: center;
    border: none;
  }
}

@media only screen and (max-width: 650px) {
  .logoMobile {
    display: inline !important;
  }
  .logo {
    display: none;
  }
}
